var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(!_vm.isDataLoaded)?_c('b-card',[_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]):_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"9","md":"8"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('LocaleTabs',{attrs:{"errors":_vm.formErrors,"name":"title"},scopedSlots:_vm._u([_vm._l((_vm.languages),function(i,k,idx){return {key:("lang" + idx),fn:function(slotScope){return [_c('ValidationProvider',{key:idx,class:slotScope.selectedTab!==idx?'hidden':'',attrs:{"vid":("title." + k),"name":_vm.$t('general.field_lang', { field: _vm.$t('labels.title'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) }),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('general.field_lang', { field: _vm.$t('labels.title'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('general.field_lang', { field: _vm.$t('labels.title'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })},model:{value:(_vm.form.title[k]),callback:function ($$v) {_vm.$set(_vm.form.title, k, $$v)},expression:"form.title[k]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]}}})],null,true)})],1),_c('b-col',{attrs:{"cols":"6","md":"6"}},[_c('validation-provider',{attrs:{"vid":"embedded_type","name":_vm.$t('labels.embedded_type')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.embedded_type')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"label","options":_vm.news_types_embedded,"reduce":function (item) { return item.value; },"placeholder":_vm.$t('labels.type')},model:{value:(_vm.form.embedded_type),callback:function ($$v) {_vm.$set(_vm.form, "embedded_type", $$v)},expression:"form.embedded_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"flag","name":_vm.$t('labels.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.type')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"label","options":_vm.news_types,"reduce":function (item) { return item.value; },"placeholder":_vm.$t('labels.type')},on:{"input":function($event){return _vm.updateSelectedData("flag")}},model:{value:(_vm.form.flag),callback:function ($$v) {_vm.$set(_vm.form, "flag", $$v)},expression:"form.flag"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"vid":"embedded_link","name":_vm.$t('labels.embedded_link')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.embedded_link'),"label-for":"embedded_link"}},[_c('b-form-input',{attrs:{"id":"embedded_link"},model:{value:(_vm.form.embedded_link),callback:function ($$v) {_vm.$set(_vm.form, "embedded_link", $$v)},expression:"form.embedded_link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.form.flag === 'championship')?_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"championships","name":_vm.$t('labels.select_championship'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.select_championship')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name_label","options":_vm.championships,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('labels.select_championship'),"multiple":""},on:{"input":function($event){return _vm.updateSelectedData()}},model:{value:(_vm.form.championships),callback:function ($$v) {_vm.$set(_vm.form, "championships", $$v)},expression:"form.championships"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3150764158)})],1):_vm._e(),(_vm.form.flag === 'league')?_c('b-col',{attrs:{"md":"6","cols":"12"}},[_c('validation-provider',{attrs:{"vid":"leagues","name":_vm.$t('labels.select_league'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.select_league')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"title_label","options":_vm.leagues,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('labels.select_league'),"multiple":""},on:{"input":function($event){return _vm.updateSelectedData()}},model:{value:(_vm.form.leagues),callback:function ($$v) {_vm.$set(_vm.form, "leagues", $$v)},expression:"form.leagues"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3286034041)})],1):_vm._e(),(_vm.teams.length)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"vid":"teams","name":_vm.$t('labels.teams'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.teams')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name_label","options":_vm.teams,"multiple":"","reduce":function (item) { return item.id; },"placeholder":_vm.$t('labels.teams')},model:{value:(_vm.form.teams),callback:function ($$v) {_vm.$set(_vm.form, "teams", $$v)},expression:"form.teams"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,4036439469)})],1):_vm._e(),(_vm.form.content_type === 'video')?_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"URL","vid":"video_url","rules":_vm.form.content_type === 'video' ? 'required':''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.youtube_url')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('labels.youtube_url'),"autocomplete":"off"},model:{value:(_vm.form.video_url),callback:function ($$v) {_vm.$set(_vm.form, "video_url", $$v)},expression:"form.video_url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3432794415)})],1):_vm._e(),(_vm.form.content_type === 'caricature')?_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"URL","vid":"video_url","rules":_vm.form.content_type === 'caricature' ? 'required':''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.video_caricature')}},[_c('b-form-input',{attrs:{"placeholder":_vm.$t('labels.video_caricature'),"autocomplete":"off"},model:{value:(_vm.form.video_url),callback:function ($$v) {_vm.$set(_vm.form, "video_url", $$v)},expression:"form.video_url"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2345264463)})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('LocaleTabs',{attrs:{"errors":_vm.formErrors,"name":"description"},scopedSlots:_vm._u([_vm._l((_vm.languages),function(i,k,idx){return {key:("lang" + idx),fn:function(slotScope){return [_c('ValidationProvider',{key:idx,class:slotScope.selectedTab!==idx?'hidden':'',attrs:{"vid":("description." + k),"name":_vm.$t('general.field_lang', { field: _vm.$t('labels.description'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) }),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('general.field_lang', { field: _vm.$t('labels.description'), lang: _vm.$t(("general." + (i.toLocaleLowerCase()))) })}},[_c('quill-editor',{attrs:{"options":_vm.snowOption},model:{value:(_vm.form.description[k]),callback:function ($$v) {_vm.$set(_vm.form.description, k, $$v)},expression:"form.description[k]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})]}}})],null,true)})],1)],1)],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"3","md":"4"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"content_type","name":_vm.$t('labels.Analytic type')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.Analytic type')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name","options":_vm.types,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('labels.Analytic type')},model:{value:(_vm.form.content_type),callback:function ($$v) {_vm.$set(_vm.form, "content_type", $$v)},expression:"form.content_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"tags","name":_vm.$t('labels.tags')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.tags')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"multiple":"","label":"name_label","options":_vm.tags,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('labels.tags')},model:{value:(_vm.form.tags),callback:function ($$v) {_vm.$set(_vm.form, "tags", $$v)},expression:"form.tags"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"vid":"analyst","name":_vm.$t('labels.analyst'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('labels.analyst')}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.direction,"label":"name_label","options":_vm.analyst,"reduce":function (item) { return item.id; },"placeholder":_vm.$t('labels.analyst')},model:{value:(_vm.form.analyst_id),callback:function ($$v) {_vm.$set(_vm.form, "analyst_id", $$v)},expression:"form.analyst_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),(_vm.form.content_type === 'image' || _vm.form.content_type === 'caricature' || _vm.form.content_type === 'video' || _vm.form.content_type === 'text')?_c('hr'):_vm._e(),(_vm.form.content_type === 'image' || _vm.form.content_type === 'caricature' || _vm.form.content_type === 'video' || _vm.form.content_type === 'text')?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"vid":"image","name":_vm.$t('labels.image'),"rules":_vm.form.content_type === 'image' || _vm.form.content_type === 'caricature' || _vm.form.content_type === 'video' ? 'required':''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ImagePreview',{attrs:{"label":_vm.$t('labels.image')},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2414677266)})],1),_c('b-col',{attrs:{"cols":"12"}})],1):_vm._e(),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('labels.status'))+" ")]),_c('b-form-checkbox',{attrs:{"checked":"true","value":"active","unchecked-value":"inactive","name":"check-button","switch":""},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1)]),_c('b-col',{attrs:{"cols":"12 mt-2"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('labels.notify_client'))+" ")]),_c('b-form-checkbox',{attrs:{"checked":"true","value":"1","unchecked-value":"0","name":"check-button","switch":""},model:{value:(_vm.form.notify_client),callback:function ($$v) {_vm.$set(_vm.form, "notify_client", $$v)},expression:"form.notify_client"}})],1)])],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"12 text-center"}},[_c('LoadingButton'),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(_vm.$t('labels.reset'))+" ")])],1)],1)],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }